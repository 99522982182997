export const leftVariants = {
  exit: {
    opacity: 0,
    transition: {
      duration: 1,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
  enter: {
    opacity: 1,
    transition: {
      duration: 1,
      delay: 0.5,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
}
// Home Page
export const headingVariants = {
  exit: {
    y: "10px",
    opacity: 0,
    transition: {
      duration: 1,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
  enter: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 1,
      delay: 1,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
}

export const imageVariants = {
  exit: i => ({
    x: i ? "-50%" : "0",
    transition: {
      duration: 1,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  }),

  enter: {
    x: "0",
    transition: {
      duration: 1,
      delay: 0.5,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
}

export const galleryVariants = {
  exit: {
    x: "100%",
    opacity: 0,
    transition: {
      duration: 0.8,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
  enter: {
    x: "0",
    opacity: 1,
    transition: {
      duration: 0.8,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
}

export const galleryItemVariants = {
  enter: direction => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: direction => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
}

import { motion } from "framer-motion"
import React from "react"
import Img from "gatsby-image"

import moduleStyles from "./mediaImage.module.css"

export const ContainImage = ({ fluid, className, ...props }) => {
  return (
    fluid && (
      <Img
        fluid={fluid}
        className={`${moduleStyles.image} ${className}`}
        {...props}
      />
    )
  )
}

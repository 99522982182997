import { useStaticQuery, graphql } from "gatsby"

export const useGalleryItems = () => {
  const { sanityGallery } = useStaticQuery(graphql`
    query {
      sanityGallery {
        galleryImages {
          image {
            asset {
              fluid(maxWidth: 1440) {
                ...GatsbySanityImageFluid
              }
            }
          }
          caption
        }
      }
    }
  `)

  return (sanityGallery && sanityGallery.galleryImages) || []
}

import React, { useState } from "react"
import { Link } from "gatsby"
import { AnimatePresence, motion } from "framer-motion"

import Logo from "../svg/logo.svg"

import { useAppContext } from "./layout"
import { MenuToggle } from "./menuToggle"

const MobileNav = ({ isOpen, onClose, setGalleryOpen }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ x: "100%" }}
          animate={{ x: "0%" }}
          exit={{ x: "100%" }}
          transition={{ duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }}
          className="fixed top-0 right-0 h-screen z-20 bg-white pt-40 w-full md:w-1/2 px-10 xl:hidden"
        >
          <nav className="grid gap-y-6 text-3xl">
            <Link to="/about" activeClassName="underline" onClick={onClose}>
              About
            </Link>
            <Link to="/wines" activeClassName="underline" onClick={onClose}>
              Wines
            </Link>
            <div
              activeClassName="cursor-pointer"
              onClick={() => {
                onClose()
                setGalleryOpen(true)
              }}
            >
              Gallery
            </div>
            <Link to="/contact" activeClassName="underline" onClick={onClose}>
              Contact
            </Link>
          </nav>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export const Header = () => {
  const { reverseHeader, setGalleryOpen } = useAppContext()
  const [navOpen, setNavOpen] = useState(false)
  return (
    <>
      <header className="absolute w-full flex items-center top-0 left-0 justify-between z-50 md:fixed">
        <Link
          className={`w-60 ${
            navOpen ? "text-black" : "text-white"
          } md:text-white ml-8 md:ml-10`}
          to="/"
        >
          <Logo />
        </Link>
        <motion.nav
          className={`hidden xl:grid grid-flow-col gap-x-6 uppercase text-lg transition-colors pl-20 w-1/2 py-10 font-bold tracking-widest ${
            reverseHeader ? "md:text-white" : "md:text-black bg-white"
          }`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <Link to="/about" activeClassName="underline">
            About
          </Link>
          <Link to="/wines" activeClassName="underline">
            Wines
          </Link>
          <div onClick={() => setGalleryOpen(true)} className="cursor-pointer">
            Gallery
          </div>
          <Link to="/contact" activeClassName="underline">
            Contact
          </Link>
        </motion.nav>
        <div
          className={`flex justify-end z-20 xl:hidden py-10 fill-current pr-8 md:pr-10 w-1/2 transition-all md:bg-transparent ${
            navOpen ? "text-black" : "text-white"
          } ${reverseHeader ? "xl:text-white" : "md:text-black md:bg-white"}`}
        >
          <MenuToggle
            onClick={() => setNavOpen(prevState => !prevState)}
            isOpen={navOpen}
          />
        </div>
      </header>
      <MobileNav
        isOpen={navOpen}
        onClose={() => setNavOpen(false)}
        setGalleryOpen={setGalleryOpen}
      />
    </>
  )
}

import React, { useState } from "react"
import { AnimatePresence, motion } from "framer-motion"

import { wrap } from "@popmotion/popcorn"

// Animation Config
import { galleryVariants, galleryItemVariants } from "../config/animation"

// Hooks & Querys
import { useGalleryItems } from "../Graphql/useGalleryItems"
import { useAppContext } from "./layout"

// SVG
import CloseIcon from "../svg/close.svg"
import NextArrow from "../svg/arrow-right.svg"
import PrevArrow from "../svg/arrow-left.svg"

import { ContainImage } from "./media/containImage"

const GallerySlide = ({ image, direction, ...props }) => {
  return (
    <motion.div
      {...props}
      className="w-full h-full flex items-center justify-center relative"
      initial="enter"
      animate="center"
      exit="exit"
      variants={galleryItemVariants}
      custom={direction}
      transition={{
        x: { type: "spring", stiffness: 300, damping: 30 },
        opacity: { duration: 0.2 },
      }}
    >
      {image && image.asset && (
        <ContainImage
          fluid={image.asset.fluid}
          className="w-full h-screen3/4 object-contain"
        />
      )}
    </motion.div>
  )
}

export const Gallery = () => {
  const { galleryOpen, setGalleryOpen } = useAppContext()
  const items = useGalleryItems()

  const [[page, direction], setPage] = useState([0, 0])

  const wrappedIndex = wrap(0, items.length, page)

  const paginate = newDirection => {
    setPage([page + newDirection, newDirection])
  }

  return (
    <AnimatePresence>
      {galleryOpen && (
        <motion.div
          className="w-full h-screen fixed z-50 bg-white top-0 left-0 grid grid-cols-gallery px-2 md:px-10"
          initial="exit"
          animate="enter"
          exit="exit"
          variants={galleryVariants}
        >
          <div
            className="absolute top-4 right-4 w-6"
            onClick={() => setGalleryOpen(false)}
          >
            <CloseIcon />
          </div>
          <button
            className="w-8 self-center outline-none focus:outline-none"
            onClick={() => paginate(-1)}
          >
            <PrevArrow />
          </button>
          <div className="px-2 md:px-20 h-screen flex items-center">
            {items.length && (
              <AnimatePresence exitBeforeEnter>
                <GallerySlide
                  {...items[wrappedIndex]}
                  key={page}
                  direction={direction}
                />
              </AnimatePresence>
            )}
          </div>
          <button
            className="w-8 self-center outline-none focus:outline-none"
            onClick={() => paginate(1)}
          >
            <NextArrow />
          </button>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

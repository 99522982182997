import React, { createContext, useContext, useState } from "react"
import { globalHistory } from "@reach/router"

import { AnimatePresence, motion } from "framer-motion"

import { Header } from "../components/header"
import { Gallery } from "./gallery"

import "./layout.css"

const AppContext = createContext({
  reverseHeader: false,
  galleryOpen: false,
})

export const useAppContext = () => useContext(AppContext)

const Layout = ({ children }) => {
  const path = globalHistory.location.pathname
  const [reverseHeader, setReverseHeader] = useState(false)
  const [galleryOpen, setGalleryOpen] = useState(false)

  return (
    <AppContext.Provider
      value={{
        reverseHeader,
        galleryOpen,
        setReverseHeader,
        setGalleryOpen,
      }}
    >
      <Header />
      <AnimatePresence initial={false}>
        <motion.main key={path} initial="exit" animate="enter" exit="exit">
          {children}
        </motion.main>
      </AnimatePresence>
      <Gallery />
    </AppContext.Provider>
  )
}

export default Layout
